import { Box } from "@chakra-ui/react";
import Seo from "../components/Seo";
import { useAutoLayout } from "../hooks/useAutoLayout";

function Page(props) {
  const Layout = useAutoLayout();

  return (
    <>
      <Seo title="404" />

      <Box mt="2rem">
        <Layout.error404Component />
      </Box>
    </>
  );
}

export default Page;
